// src/pages/PasswordPage.js
import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import PasswordForm from '../components/PasswordForm';
import BackGround from '../components/BackGround';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function PasswordPage() {
  const query = useQuery();
  const userId = query.get('userId');
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const firstName = sessionStorage.getItem('firstName');
  const surname = sessionStorage.getItem('surname');
  const email = sessionStorage.getItem('email');
  const phone = sessionStorage.getItem('phone');
  const gender = sessionStorage.getItem('gender')
  const dob = sessionStorage.getItem('dob');
  const addressData = JSON.parse(sessionStorage.getItem('address'));

  const handleSubmitPassword = async (password) => {
    setLoading(true);
    try {
      const response = await fetch('/api/auth/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ firstName, surname, email, phone, gender, dob, addressData, password }),
      });
      if (!response.ok) {
        throw new Error('An error occurred. Please try again.');
      }
      const data = await response.json();
      sessionStorage.removeItem('firstName');
      sessionStorage.removeItem('surname');
      sessionStorage.removeItem('phone');
      sessionStorage.removeItem('gender');
      sessionStorage.removeItem('dob');
      sessionStorage.removeItem('address');
      localStorage.setItem('sessionId', data.sessionId);
      navigate(`/verification?userId=${data.userId}`);
    }
    catch (err) {
      setError(err.message);
      setLoading(false);
      if (err.message === 'Password already set for this user redirecting to login') {
        setTimeout(() => {
          navigate(`/login`);
        }, 3000);
    }
  }
  };

  return (
    <div>
      <BackGround />
      <h2>Set Your Password</h2>
      <PasswordForm onSubmitPassword={handleSubmitPassword} />
      {error && <p className='error'>{error}</p>}
      {loading && <div className="overlay"></div>}
      {loading && <div className="spin"></div>}
    </div>
  );
}

export default PasswordPage;
